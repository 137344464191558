import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Este mult mai mult decât o istorie a poporului lui Dumnezeu; cursul surprinde aspectele fundamentale ale planului divin de răscumpărare a omului derulat în secole de istorie umană.`}</MDXTag>
      <MDXTag name="p" components={components}>{`„Călătorie prin V. Testament” a lui Samuel J. Schultz este cartea principală din bibliografia cursului și prin cele 15 lecții ale cursului fi sigur că oferă resurse mai mult decât necesare pentru orice slujitor care vrea să aibă o perspectivă întreagă despre Cuvântul lui Dumnezeu.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Tabelele cronologice te vor ajuta să asociezi corect împărații și profeții Vechiului Testament,hărțile și celelalte materiale generoase vor face din studiul acestui curs o plăcere pentru oricine este gata să cunoască pentru a împlini și a sluji din Cuvântul lui Dumnezeu.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Vei avea multe surprize în timp ce studiezi acest curs!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Cum vă simțiți atunci când vă gândiți la poporul ales de Dumnezeu și-l vedeți tremurând în fața uriașului Goliat?! Eu cred că omorârea lui Goliad de către David este o istorie măreață de zidire prin credință! Ea se conformează cu siguranță tiparului lui Dumnezeu: (“Nici prin putere, nici prin tărie, ci prin Duhul meu zice Domnul oștirilor.”) Zaharia 4:6 sau (“Dumnezeu a ales lucrurile slabe să facă de rușine pe cele tari.”) 1 Cor. 1:27”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    